import { createContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { fetchLink } from '../Config/clientCfg';

export const AppContext = createContext();

const AppProvider = ({children}) => {

   const [isUserLogged, setIsUserLogged] = useState(false)
   const [store, setStore] = useState([])
   const [openHours, setOpenHours] = useState([])

    const handleCheckToken = (tokenResponse) => {
        if(tokenResponse.length === 1) {
            setIsUserLogged(true)
        }   else {
            setIsUserLogged(false)
        }
    }
    const handleFetchState = (state) => {
        if(state === 0) {
            setIsAppOn(false)
        }   else {
            setIsAppOn(true)
        }
    }

    const [isAppOn, setIsAppOn] = useState(true)
    const handleSetIsAppOn = () => {
        setIsAppOn(!isAppOn)
        const appObject = {
            status: !isAppOn
        }
        fetch(`${fetchLink}toggleAppState`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(appObject)
        })
    }

    useEffect(() => {
        fetch(`${fetchLink}getAppState`, {
            method: 'POST',
        })
        .then(response => response.json())
        .then(data => handleFetchState(data.isOn))
    }, [])

    useEffect(() => {
        fetch(`https://sziszakebab.pl/getStore`, {
            method: 'POST',
        })
        .then(response => response.json())
        .then(data => setStore(data))

        fetch(`https://sziszakebab.pl/getOpenHours`, {
            method: 'POST',
        })
        .then(response => response.json())
        .then(data => setOpenHours(data))
    }, [])

   useEffect(() => {
       const cookies = new Cookies();
       const isLoginCookieExist = cookies.get('workerLogged')
       const isLoginTokenExist = cookies.get('authCode')

       if(isLoginCookieExist !== undefined && isLoginTokenExist !== undefined) {
           fetch(`${fetchLink}loginForceAuth`, {
               method: 'POST',
               headers: {
                   'Content-Type': 'application/json'
               },
               body: JSON.stringify({ token: isLoginTokenExist })
           })
           .then(response => response.json())
           .then(data => handleCheckToken(data))
       }
   }, [])

   const [opts, setOpts] = useState([])
   const handleSetOpts = (opt) => {
       setOpts(opt)
   }
    
    return ( 
        <AppContext.Provider value={{ handleSetIsAppOn, isAppOn, opts, handleSetOpts, openHours, isUserLogged, setIsUserLogged, store }}>
            {children}
        </AppContext.Provider>
     );
}
 
export default AppProvider;