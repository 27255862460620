import { useState, useContext } from 'react'
import { AppContext } from '../Context/AppContext'
import '../Styles/Login.css'
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchLink } from '../Config/clientCfg'
import Cookies from 'universal-cookie';

const Login = () => {

    const { setIsUserLogged } = useContext(AppContext)

    const [isDataLoading, setIsDataLoading] = useState(false)
    const [isLoginError, setIsLoginError] = useState(false)

    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')    
    const handleSetLogin = (e) => {
        setLogin(e.target.value)
    }
    const handleSetPassword = (e) => {
        setPassword(e.target.value)
    }
    const handleSubmitLogin = (e) => {
        e.preventDefault();
        setIsDataLoading(true)
        setIsLoginError(false)
        const loginObject = {
            login, password
        }
        fetch(`${fetchLink}tryLogin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loginObject)
        })
        .then(response => response.json())
        .then(data => handleTrySignIn(data))
    }

    const handleTrySignIn = (loginResponse) => {
        const cookies = new Cookies();
        setIsDataLoading(false)
        if(loginResponse.length === 1) {
            setIsUserLogged(true)
            cookies.set('workerLogged', true, {
                maxAge: 5184000
            });
            cookies.set('authCode', loginResponse[0].authCode, {
                maxAge: 5184000
            })
        }   else {
            setIsLoginError(true)
        }
    } 

    return (
        <div className="login">
            <form onSubmit={handleSubmitLogin}>
                <h2>Logowanie do systemu zarządzenia zamówieniami</h2>
                <label>Login</label>
                <input type="text" value={login} onChange={handleSetLogin}/>
                <label>Hasło</label>
                <input type="password" value={password} onChange={handleSetPassword}/>
                <button disabled={isDataLoading ? true : false}>{ isDataLoading ? <CircularProgress style={{height: '30px', width: '30px', fontWeight: '500', color: 'white'}}/> : 'Zaloguj się' }</button>

                { isLoginError ? <p>Wystąpił błąd podczas logowania, sprawdź porpawność hasła lub loginu i spróbuj ponownie</p> : null }
            </form>
        </div>
      );
}
 
export default Login;