import NotFoundOrders from "./NotFoundOrders";
import { useContext,useState } from "react";
import { AppContext } from "../../Context/AppContext";
import PrintIcon from '@material-ui/icons/Print';
import printJS from 'print-js';

const RejectedOrders = (props) => {

    const list = props.orderList

    return ( 
        <div className="displayOrders">
            <h2>Odrzucone zamówienia</h2>
            { list && list.map(item => <DisplayItem key={item.id} data={item}/>) }
            { list.length === 0 ? <NotFoundOrders /> : null }
        </div>
     );
}
 
export default RejectedOrders;

const DisplayItem = ({data, refresh}) => {
    const { store } = useContext(AppContext)

    const products = JSON.parse(data.products)

    const handleDisplayOrderHour = (date) => {
        const DO = new Date(date)
        let hour = DO.getHours()
        if(hour < 10) {
            hour = `0${hour}`
        }
        let minute = DO.getMinutes()
        if(minute < 10) {
            minute = `0${minute}`
        }
        const hourToDisplay = `${hour}:${minute}`
        return hourToDisplay
    }

    const handleDisplayPrintDate = () => {
        const DO = new Date(data.orderDate)
        const year = DO.getFullYear();
        let month = DO.getMonth() + 1;
        if(month < 10) {
            month = `0${month}`
        }
        let day = DO.getDate();
        if(day < 10) {
            day = `0${day}`
        }
        let hour = DO.getHours();
        if(hour < 10) {
            hour = `0${hour}`
        }
        let minute = DO.getMinutes();
        if(minute < 10) {
            minute = `0${minute}`
        }

        const dateToPrint = `${year}-${month}-${day}  godz. ${hour}:${minute}`
        return dateToPrint
    }
    const handleDisplayPricePaymentType = () => {
        if(data.paymentType === '1') {
            return 'karta'
        }   else {
            return 'gotówka'
        }
    }
    const handleDisplayPrintAdress = () => {
        let addressToPrint = ''
        if(data.deliveryType === "1") {
            addressToPrint = `<li>Adres: <span style='font-weight: bold'>ul. ${data.street} nr. ${data.homenumber}${data.flatNumber !== "" ? ' / ' : ""}${data.flatNumber !== "" ? data.flatNumber : ""} </br> ${data.postCode} ${data.city}</span></li>`
        }   else if(data.deliveryType === "2") {
            addressToPrint = `<li>Sposób dostawy: <span style='font-weight: bold'>Odbiór osobisty</span></li>`
        }
        
        return addressToPrint
    }
    const handleDisplayPrintRefound = () => {
        if(data.paymentType === '2') {
            if(data.paymentAmount === "") {
                return ''
            }   else {
                return `<p id='printProductHeader'>Kwota do wydania: <span style='font-weight: bold'>${data.paymentAmount - data.finalPrice}zł</span></p>`
            }
        }   else {
            return ''
        }
    }
    const handleDisplayPrintProducts = () => {
        const productsObject = JSON.parse(data.products);
        let productsString = ''
        for(let i = 0; i < productsObject.length; i++) {
            //nazwa
            productsString = productsString + `<p id='printProductHeader'>${i+1}. ${store.store[productsObject[i].categoryId].products[productsObject[i].productId].name} <span id='priceProductHeaderSpan'>- ${productsObject[i].productCount} szt.</span></p>`
            //rozmiar
            if(productsObject[i].sizeChoosed === 0) {
                productsString = productsString + `<div id='orderOption'>Rozmiar: standardowy</div>`
            }   else if(productsObject[i].sizeChoosed === 1) {
                productsString = productsString + `<div id='orderOption'>Rozmiar: big</div>`
            }   else if(productsObject[i].sizeChoosed === 2) {
                productsString = productsString + `<div id='orderOption'>Rozmiar: XXL</div>`
            }
            //mieso
            if(productsObject[i].meatChoosed === 0) {
                productsString = productsString + `<div id='orderOption'>Mięso: kurczak</div>`
            }   else if(productsObject[i].meatChoosed === 1) {
                productsString = productsString + `<div id='orderOption'>Mięso: wołowina</div>`
            }   else if(productsObject[i].meatChoosed === 2) {
                productsString = productsString + `<div id='orderOption'>Mięso: mieszane</div>`
            }
            //sos
            if(productsObject[i].sosChoosed === 0) {
                productsString = productsString + `<div id='orderOption'>Sos: łagodny</div>`
            }   else if(productsObject[i].sosChoosed === 1) {
                productsString = productsString + `<div id='orderOption'>Sos: mieszany</div>`
            }   else if(productsObject[i].sosChoosed === 2) {
                productsString = productsString + `<div id='orderOption'>Sos: ostry</div>`
            }
            //dodatki
            if(productsObject[i].addedChoosed.length !== 0) {
                productsString = productsString + `<div id='orderOption'>Dodatki: ${productsObject[i].addedChoosed.join(', ')}</div>`
            }
            //dodatkowe sosy
            let sosList = ''
            let count0 = 0
            let count1 = 0
            let count2 = 0
            for(let x = 0; x < productsObject[i].addedSos.length; x++) {
                if(productsObject[i].addedSos[x] === 0) {
                    count0 = count0 + 1
                }   else if(productsObject[i].addedSos[x] === 1) {
                    count1 = count1 + 1
                }   else if(productsObject[i].addedSos[x] === 2) {
                    count2 = count2 + 1
                }
            }
            if(count0 !== 0) {
                sosList = sosList + `&nbsp;&nbsp;&nbsp;Sos łagodny ${count0}szt. </br> `
            }
            if(count1 !== 0) {
                sosList = sosList + `&nbsp;&nbsp;&nbsp;Sos mieszany ${count1}szt. </br> `
            }
            if(count2 !== 0) {
                sosList = sosList + `&nbsp;&nbsp;&nbsp;Sos ostry ${count2}szt.`
            }
            if(count0 !== 0 || count1 !== 0 || count2 !== 0) {
                productsString = productsString + `<div id='orderOption'>Dodatkowe sosy:</br> ${sosList}</div>`
            }
            productsString = productsString + `<div id='orderOption'>Cena produktu: <span style='font-weight: bold'>${productsObject[i].finishPrice}zł</span></div>`
        }
        return productsString
    }
    const handleDisplayUwagi = () => {
        let uwagi = ''
        if(data.uwagi !== '') {
            uwagi = `<b>Uwagi do zamówienia</b><p id='uwagi'>${data.uwagi}</p>`
        }
        return uwagi
    }
    const [ticketPrinted, setTicketPrinted] = useState(false)
    const handlePrintTicket = () => {
        printJS({
            printable: [/*`https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=https://orders.sziszakebab.pl/manage/deliveryPushState/${data.id}`*/],
            type: 'image',
            imageStyle: 'margin-left: calc(50% - 100px)',
            style: `
            * {
                font-family: 'Arial'
            }
            header {
                margin-bottom: 1em;
            }
            header > h3 {
                text-align: center;
                margin-bottom: 5px;
                font-size: 2rem;
            }
            header > p {
                display: block;
                text-align: center;
                font-size: 1.1rem;
            }
            li {
                font-size: 1.8rem;
            }
            b {
                padding: 0 10px;
                font-size: 1.75rem;
                display: block;
                margin-bottom: .5em;
                margin-top: .5em;
            }
            p {
                padding: 0 10px;
                margin: 0;
            }
            #uwagi {
                font-size: 1.6rem;
            }
            #printProductHeader {
                font-size: 1.8rem;
                margin: 10px 0px
            }
            #priceProductHeaderSpan {
                font-weight: 700;
            }
            #orderOption {
                padding: 0 35px;
                font-size: 1.6rem;
                margin-bottom: 5px;
            }
            #deliveryQRinfo {
                text-align: center;
                font-size: 1.2rem;
                margin-bottom: 2em
            }
            #deliveryQRheader {
                text-align: center;
                margin-top: 2em
            }
            `,
            header: `<div>
              <header>
                <h3>Zamówienie nr. ${data.id}</h3>
                <p>Zamówienie z dnia: ${handleDisplayPrintDate()}</p>
              </header>
              <b>Informacje o dostawie</b>
              <ul>
                <li>Imię i nazwisko: <span style='font-weight: bold'>${data.firstname} ${data.surname}</span></li>
                ${handleDisplayPrintAdress()}
                <li>Nr. kontaktowy: <span style='font-weight: bold'>${data.phone}</span></li>
                <li>Czas dostawy: <span style='font-weight: bold'>${data.deliveryTime}</span></li>
              </ul>
              <b>Zamówienie</b>
                ${handleDisplayPrintProducts()}
                ${handleDisplayUwagi()}
               ${/*  <b id='deliveryQRheader'>Dostarczyłeś już zamówienie?</b>
                <p id='deliveryQRinfo'>Zeskanuj kod QR i potwierdź dostarczenie</p>*/''}
                <b>Płatność</b>
                <p id='printProductHeader'>Cena dowozu: <span style='font-weight: bold'>${data.deliveryValue}zł</span></p>
                <p id='printProductHeader'>Kwota do zapłaty: <span style='font-weight: bold'>${data.finalPrice}zł</span></p>
                ${handleDisplayPrintRefound()}
                <p id='printProductHeader'>Sposób płatności: <span style='font-weight: bold'>${handleDisplayPricePaymentType()}</span></p>
            </div>`,
         })
         setTicketPrinted(true)
    }

    return ( 
        <div className="orderItem">
            <p className='orderItem--number'>Zamówienie nr. {data.id}</p>
            <span id='orderDate'>Zamówienie złożone o godzinie: <b>{handleDisplayOrderHour(data.orderDate)}</b></span>
            <label>Zamówione produkty</label>
            { products && products.map((item, i) => <DisplayOrderedItem key={i} data={item} count={i}/> ) }

            <label id='rejectedOrd'>Odrzucone</label>
            <button id="printTicket" onClick={ticketPrinted ? null : handlePrintTicket}>Wydrukuj <PrintIcon /></button>
        </div>
     );
}

const DisplayOrderedItem = ({data, count}) => {

    const { store } = useContext(AppContext)

    const handleDisplaySizeChoosed = (size) => {
        switch (size) {
            case 0:
                return 'Standardowy'
            case 1:
                return 'Big'
            case 2:
                return 'XXL'
        
            default:
                break;
        }
    }

    const hanldeDisplayMeat = (meat) => {
        switch (meat) {
            case 0:
                return 'Kurczak'
            case 1:
                return 'Wołowina'
            case 2:
                return 'Mieszane'
        
            default:
                break;
        }
    }

    const handleDisplaySosChoosed = (sos) => {
        switch (sos) {
            case 0:
                return 'Łagodny'
            case 1:
                return 'Mieszany'
            case 2:
                return 'Ostry'
            case 3: 
                return 'Bez sosu'
        
            default:
                break;
        }
    }

    const handleDisplayAddeds = (addeds) => {
        const displayArray = addeds.join(', ')
        return displayArray
    }

    const handleDisplayAddedSos = (addedsos) => {
        let count0 = 0
        let count1 = 0
        let count2 = 0
        for(let i = 0; i < addedsos.length; i++) {
            if(addedsos[i] === 0) {
                count0 = count0 + 1
            }   else if(addedsos[i] === 1) {
                count1 = count1 + 1
            }   else if(addedsos[i] === 2) {
                count2 = count2 + 1
            }
        }
        let stringToDisplay = ''
        if(count0 !== 0) {
            stringToDisplay = stringToDisplay + `Sos łagodny ${count0}szt., `
        }
        if(count1 !== 0) {
            stringToDisplay = stringToDisplay + `Sos mieszany ${count1}szt., `
        }
        if(count2 !== 0) {
            stringToDisplay = stringToDisplay + `Sos ostry ${count2}szt.`
        }
        return stringToDisplay
    }

    return ( 
        <div className="orderItem--product">
            {count+1}. { store.store[data.categoryId].products[data.productId].name }<span id='productOrderCount'> - {data.productCount} szt.</span>
            <ul>
            { data.sizeChoosed === '' ? null : <li>Rozmiar: {handleDisplaySizeChoosed(data.sizeChoosed)}</li> }
                { data.meatChoosed === '' ? null : <li>Mięso: {hanldeDisplayMeat(data.meatChoosed)}</li> }
                { data.sosChoosed === '' ? null : <li>Sos: {handleDisplaySosChoosed(data.sosChoosed)}</li> }
                { data.addedChoosed.length === 0 ? null : <li>Dodatki: {handleDisplayAddeds(data.addedChoosed)}</li> }
                { data.addedSos.length === 0 ? null : <li>Dodatkowe sosy: {handleDisplayAddedSos(data.addedSos)}</li> }
            </ul>
        </div>
     );
}