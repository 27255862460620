import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../Context/AppContext'
import { fetchLink } from '../../Config/clientCfg';
import Select from 'react-select'
import ChangedOrder from './ChangedOrder';
import NotFoundOrders from './NotFoundOrders';
import CachedIcon from '@material-ui/icons/Cached';

const NewOrders = (props) => {

    const { isAppOn, handleSetIsAppOn } = useContext(AppContext)

    const list = props.orderList
    const handleRefresh = () => {
        props.refresh()
    }

    useEffect(() => {
        if(props.state === true) {
            props.dismiss()
        }
        // eslint-disable-next-line
    }, [])

    return ( 
        <div className="displayOrders">
            <div className="toggleOnApp">
                <p>Aplikacja jest <span id={isAppOn ? 'appOnBtn' : 'appOffBtn'}>{ isAppOn ? 'włączona' : 'wyłączona' }</span></p>
                <button onClick={handleSetIsAppOn}>{isAppOn ? 'wyłącz aplikację' : 'włącz aplikację'}</button>
            </div>
            <div className="refreshBtn" onClick={handleRefresh}>Odśwież listę zamówień <CachedIcon /></div>
            <h2>Nowe zamówienia</h2>
            { list && list.map(item => <DisplayItem key={item.id} data={item} refresh={props.refresh}/>) }
            { list.length === 0 ? <NotFoundOrders /> : null }
        </div>
     );
}
 
export default NewOrders;

const DisplayItem = ({data, refresh}) => {

    const { openHours, handleSetOpts, opts } = useContext(AppContext)

    const products = JSON.parse(data.products)
    
    const handleDeliveryType = (deliveryType) => {
        switch (deliveryType) {
            case "1":
                return 'Dowóz'
            case "2":
                return 'Odbiór w lokalu'
        
            default:
                break;
        }
    }

    const options = []

    useEffect(() => {
        const DO = new Date()
        const startYear = DO.getFullYear()
        const startMonth = DO.getMonth()
        const startDay = DO.getDate()
        const dayIndex = DO.getDay()
        let startHours = DO.getHours();
        let startMinutes = DO.getMinutes();
        if(startMinutes > 0 && startMinutes <15) {
            startMinutes = 15
        }   else if(startMinutes >= 15 && startMinutes < 30) {
            startMinutes = 30
        }   else if(startMinutes >= 30 && startMinutes < 45) {
            startMinutes = 45
        }   else {
            startHours = startHours+1
            startMinutes = 0
        }
        const startHour = new Date(startYear, startMonth, startDay, startHours, startMinutes);
        let endHour = ''
        let eh = openHours.openhours[dayIndex].hours
        let endhour = `${eh[0]}${eh[1]}`
        let endminute = `${eh[3]}${eh[4]}`
        if(typeof(openHours.openhours[dayIndex].hours) === 'string') {
            eh = eh.split('-')[1]
            endhour = `${eh[0]}${eh[1]}`
            endminute = `${eh[3]}${eh[4]}`
            endHour = new Date(startYear, startMonth, startDay, endhour, endminute)
        }   else {
            eh = openHours.openhours[dayIndex].hours[1].split('-')[1]
            endhour = `${eh[0]}${eh[1]}`
            endminute = `${eh[3]}${eh[4]}`
            endHour = new Date(startYear, startMonth, startDay+1, endhour, endminute)
        }
        
        let hourToAdd = startHour;
        while(hourToAdd.getTime() <= endHour.getTime()) {
            let hour = hourToAdd.getHours()
            if(hour < 10) {
                hour = `0${hour}`
            }
            let minutes = hourToAdd.getMinutes()
            if(minutes < 10) {
                minutes = `0${minutes}`
            }
            const correctDate = `${hour}:${minutes}`

            const optionObject = { value: `${correctDate}`, label: `${correctDate}` }
            options.push(optionObject)
            hourToAdd = new Date(hourToAdd.getTime() + 900000)
        }
        handleSetOpts(options)
        // eslint-disable-next-line
    }, [])

    const [isRejectAccept, setIsRejectAccept] = useState(false)
    const handleAccepteReject = () => {
        setIsRejectAccept(true)
    }
    const handleRejectBack = () => {
        setIsRejectAccept(false)
    }
    const handleRejectOrder = () => {
        const infoObject = {
            id: data.id
        }
        fetch(`${fetchLink}manage/rejectOrder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(infoObject)
        })
        .then(response => refresh())
        setIsRejectAccept(false)
    }

    const handleDisplayOrderHour = (date) => {
        const DO = new Date(date)
        let hour = DO.getHours()
        if(hour < 10) {
            hour = `0${hour}`
        }
        let minute = DO.getMinutes()
        if(minute < 10) {
            minute = `0${minute}`
        }
        const hourToDisplay = `${hour}:${minute}`
        return hourToDisplay
    }

    const [newDate, setNewDate] = useState(options[1])
    const handleSetNewDate = (e) => {
        setNewDate(e.value)
    }
    const handleAcceptOrder = () => {
        const infoObject = {
            id: data.id,
            time: newDate
        }
        fetch(`${fetchLink}manage/acceptOrder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(infoObject)
        })
        .then(() => handleGetAcceptResponse())
    }

    const [isChanged, setIsChange] = useState(false)
    const handleGetAcceptResponse = () => {
        setIsChange(true)
        refresh()
    }

    return ( 
        <div className="orderItem">
            { isChanged ? <ChangedOrder /> : <> 
            { isRejectAccept ? <div className="accepteReject">
                <div className="rejectBox">
                    <h2>Napewno chcesz odrzucić zamówienie nr. {data.id}</h2>
                    <label>
                        <button onClick={handleRejectBack}>Anuluj</button>
                        <button onClick={handleRejectOrder}>Odrzuć</button>
                    </label>
                </div>
            </div> : null }
            <p className='orderItem--number'>Zamówienie nr. {data.id}</p>
            <span id='orderDate'>Zamówienie złożone o godzinie: <b>{handleDisplayOrderHour(data.orderDate)}</b></span>
            <label>Zamówione produkty</label>
            { products && products.map((item, i) => <DisplayOrderedItem key={i} data={item} count={i}/> ) }

            <label>Informacje o dostawie</label>
            <span>Rodzaj dostawy: {handleDeliveryType(data.deliveryType)}</span>
            <span>Czas dostawy: {data.deliveryTime}</span>
            <label>Zarządzanie zamówieniem</label>
            <div className="manageOrder">
                <div className="changeDeliveryTime">
                    <span>Okreś czas dostawy</span>
                    <label>
                    <Select id='timeChange' options={opts} onChange={handleSetNewDate}/>
                    <button onClick={handleAcceptOrder}>Zaakceptuj</button>
                    </label>
                </div>
                <button id='rejectOrderBtn' onClick={handleAccepteReject}>Odrzuć zamówienie</button>
            </div>
            </> }
        </div>
     );
}

const DisplayOrderedItem = ({data, count}) => {

    const { store } = useContext(AppContext)

    const handleDisplaySizeChoosed = (size) => {
        switch (size) {
            case 0:
                return 'Standardowy'
            case 1:
                return 'Big'
            case 2:
                return 'XXL'
        
            default:
                break;
        }
    }

    const hanldeDisplayMeat = (meat) => {
        switch (meat) {
            case 0:
                return 'Kurczak'
            case 1:
                return 'Wołowina'
            case 2:
                return 'Mieszane'
        
            default:
                break;
        }
    }

    const handleDisplaySosChoosed = (sos) => {
        switch (sos) {
            case 0:
                return 'Łagodny'
            case 1:
                return 'Mieszany'
            case 2:
                return 'Ostry'
            case 3: 
                return 'Bez sosu'
        
            default:
                break;
        }
    }

    const handleDisplayAddeds = (addeds) => {
        const displayArray = addeds.join(', ')
        return displayArray
    }

    const handleDisplayAddedSos = (addedsos) => {
        let count0 = 0
        let count1 = 0
        let count2 = 0
        for(let i = 0; i < addedsos.length; i++) {
            if(addedsos[i] === 0) {
                count0 = count0 + 1
            }   else if(addedsos[i] === 1) {
                count1 = count1 + 1
            }   else if(addedsos[i] === 2) {
                count2 = count2 + 1
            }
        }
        let stringToDisplay = ''
        if(count0 !== 0) {
            stringToDisplay = stringToDisplay + `Sos łagodny ${count0}szt., `
        }
        if(count1 !== 0) {
            stringToDisplay = stringToDisplay + `Sos mieszany ${count1}szt., `
        }
        if(count2 !== 0) {
            stringToDisplay = stringToDisplay + `Sos ostry ${count2}szt.`
        }
        return stringToDisplay
    }

    return ( 
        <div className="orderItem--product">
            { store.store[data.categoryId].products[data.productId].name }<span id='productOrderCount'> - {data.productCount} szt.</span>
            <ul>
                { data.sizeChoosed === '' ? null : <li>Rozmiar: {handleDisplaySizeChoosed(data.sizeChoosed)}</li> }
                { data.meatChoosed === '' ? null : <li>Mięso: {hanldeDisplayMeat(data.meatChoosed)}</li> }
                { data.sosChoosed === '' ? null : <li>Sos: {handleDisplaySosChoosed(data.sosChoosed)}</li> }
                { data.addedChoosed.length === 0 ? null : <li>Dodatki: {handleDisplayAddeds(data.addedChoosed)}</li> }
                { data.addedSos.length === 0 ? null : <li>Dodatkowe sosy: {handleDisplayAddedSos(data.addedSos)}</li> }
            </ul>
        </div>
     );
}