import { useContext } from 'react';
import { AppContext } from '../Context/AppContext'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import '../Styles/App.css'
import Login from './Login';
import Dashboard from './Dashboard'

const App = () => {

  const { isUserLogged } = useContext(AppContext)

  return ( 
    <Router>
    <div className="wrapper">
      <Switch>
        <Route exact path='/'>{ isUserLogged ? <Redirect to='/dashboard' /> : <Login /> }</Route>
        <Route exact path='/dashboard*'>{ isUserLogged ? <Dashboard /> : <Redirect to='/' /> }</Route>
      </Switch>
    </div>
    </Router>
   );
}
 
export default App;