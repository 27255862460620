import '../Styles/Dashboard.css'
import { useEffect, useState } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import Sound from 'react-sound';
import RingTone from '../Data/Short.mp3'
import ClearIcon from '@mui/icons-material/Clear';

import { fetchLink } from '../Config/clientCfg'

import ChooseOrderType from './DashboardComponents/ChooseOrderType'
import NewOrders from './DashboardComponents/NewOrders'
import InPrepareOrder from './DashboardComponents/InPrepareOrder'
import InDeliveryOrders from './DashboardComponents/InDeliveryOrders'
import FinishedOrders from './DashboardComponents/FinishedOrders'
import RejectedOrders from './DashboardComponents/RejectedOrders'

const Dashboard = () => {

    const [activeLink, setActiveLink] = useState(0)

    const [newOrders, setNewOrders] = useState([])
    const [realizedOrders, setRealizedOrders] = useState([])
    const [inDeliveryOrders, setInDeliveryOrders] = useState([])
    const [finishedOrders, setFinishedOrders] = useState([])
    const [rejectedOrders, setRejectedOrders] = useState([])

    const [newOrderCount, setNewOrderCount] = useState(0)

    const handleSetNewLinkActive = (id) => {
        setActiveLink(id)
    }

    useEffect(() => {
        handleRefreshData()
        // eslint-disable-next-line 
    }, [])

    const handleRefreshData = async () => {
        await fetchNewOrders();
        await fetchRealizedOrders();
        await fetchInDeliveryOrders();
        await fetchFinishedOrders();
        await fetchRejectedOrders();
    }       

    const fetchNewOrders = async () => {
       await fetch(`${fetchLink}orders/getNewOrders`, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json'
           }
       })
       .then(response => response.json())
       .then(data => {setNewOrders(data); setNewOrderCount(data.length)})
    }

    const fetchRealizedOrders = async () => {
        await fetch(`${fetchLink}orders/getRealizedOrders`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => setRealizedOrders(data))
    }

    const fetchInDeliveryOrders = async () => {
        await fetch(`${fetchLink}orders/getDeliveryOrders`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => setInDeliveryOrders(data))
    }

    const fetchFinishedOrders = async () => {
        await fetch(`${fetchLink}orders/getFinishedOrders`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => setFinishedOrders(data))
    }

    const fetchRejectedOrders = async () => {
        await fetch(`${fetchLink}orders/getRejectedOrders`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => setRejectedOrders(data))
    }

    const [counter, setCounter] = useState(60)
    useEffect(() => {
        if(counter > 0) {
            setTimeout(() => setCounter(counter - 1), 1000);
       }   else {  
           handleRefreshData()
           setCounter(60)
       }
       // eslint-disable-next-line
    }, [counter])

    const [playSound, setPlaySound] = useState(false)
    const [showNotification, setShowNotification] = useState(false)
    useEffect(() => {
        if(newOrderCount !== 0) {
        console.log(`Zamówien nowych: ${newOrderCount}`);
        setPlaySound(true)
        setShowNotification(true)
        }
    }, [newOrderCount])

    const handleDismissNotification = () => {
        setPlaySound(false)
        setShowNotification(false)
    }

    return ( 
        <div className="dashboard">
            {playSound ? <Sound
      url={RingTone}
      playStatus={Sound.status.PLAYING}
      loop
      onFinishedPlaying={() => setPlaySound(false)}
    /> : null}
            <div className="dashboard__menu">
                <Link to='/dashboard/nowe' onClick={() => handleSetNewLinkActive(1)} id={activeLink === 1 ? 'activeLink' : null}>Nowe</Link>
                <Link to='/dashboard/realizowane' onClick={() => handleSetNewLinkActive(2)} id={activeLink === 2 ? 'activeLink' : null}>Realizowane</Link>
                <Link to='/dashboard/w-dostawie' onClick={() => handleSetNewLinkActive(3)} id={activeLink === 3 ? 'activeLink' : null}>W dostawie</Link>
                <Link to='/dashboard/zakonczone' onClick={() => handleSetNewLinkActive(4)} id={activeLink === 4 ? 'activeLink' : null}>Zakończone</Link>
                <Link to='/dashboard/anulowane' onClick={() => handleSetNewLinkActive(5)} id={activeLink === 5 ? 'activeLink' : null}>Anulowane</Link>
            </div>
            <Switch>
                    <Route exact path='/dashboard'><ShowNotification dismiss={handleDismissNotification} state={showNotification}/></Route>
                    <Route exact path='/dashboard/nowe'><ShowNotification dismiss={handleDismissNotification} state={showNotification}/></Route>
                    <Route exact path='/dashboard/realizowane'><ShowNotification dismiss={handleDismissNotification} state={showNotification}/></Route>
                    <Route exact path='/dashboard/w-dostawie'><ShowNotification dismiss={handleDismissNotification} state={showNotification}/></Route>
                    <Route exact path='/dashboard/zakonczone'><ShowNotification dismiss={handleDismissNotification} state={showNotification}/></Route>
                    <Route exact path='/dashboard/anulowane'><ShowNotification dismiss={handleDismissNotification} state={showNotification}/></Route>
                </Switch>
            <div className="dashboard__orders">
                <Switch>
                    <Route exact path='/dashboard'><ChooseOrderType /></Route>
                    <Route exact path='/dashboard/nowe'><NewOrders orderList={newOrders} refresh={handleRefreshData} dismiss={handleDismissNotification} state={showNotification}/></Route>
                    <Route exact path='/dashboard/realizowane'><InPrepareOrder orderList={realizedOrders} refresh={handleRefreshData}/></Route>
                    <Route exact path='/dashboard/w-dostawie'><InDeliveryOrders orderList={inDeliveryOrders} refresh={handleRefreshData}/></Route>
                    <Route exact path='/dashboard/zakonczone'><FinishedOrders orderList={finishedOrders}/></Route>
                    <Route exact path='/dashboard/anulowane'><RejectedOrders orderList={rejectedOrders}/></Route>
                </Switch>
            </div>
        </div>
     );
}
 
export default Dashboard;

const ShowNotification = (props) => {
    return ( 
        <>
        {props.state === true ? 
        <div className="newOrderNotification">
            <div className="newOrderNotification--header">
                <ClearIcon onClick={props.dismiss}/>
            </div>
            <div className="newOrderNotification--content">
                <h3>Nowe zamówienia czekają na odbiór</h3>
                <Link to='/dashboard/nowe' onClick={props.dismiss}>Przejdź do nowych zamówień</Link>
            </div>
        </div> : null }
        </>
     );
}